<template>
<div class="uerinfo-company">
  <div class="from-box">

      <p class="title">申请人信息</p>
      <p class="info">企业证件信息仅用于企业认证，不会泄露您的任何证件信息</p>

    <div class="from-content">
    <van-form validate-first class="from" ref="ruleForm">


      <div class="from-top">

      <van-field
        required
        input-align="right"
        error-message-align="right"
        size="large"
        readonly
        clickable
        is-link
        name="applierType"
        :value="from.applierType"
        label="申请人类型"
        placeholder="请选择"
        :rules=rules.applierType
        @click="pickerChange('applierType')"
      />



        <van-action-sheet
          v-model="state.applierType"
          :actions="actions"
          cancel-text="取消"
          close-on-click-action
          @select="onSelect($event,'applierType')"
          @cancel="state.applierType = false"
        />



      <van-field
        label-width="200"
        required
        input-align="right"
        error-message-align="right"
        size="large"
        v-model="from.legalPersonName"
        label="法定代表人姓名"
        name="legalPersonName"
        placeholder="请填写"
        maxlength="20"
        :rules=rules.legalPersonName
      />


      <div class="text">


        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="法定代表人身份证号"
          readonly
          label-width="200"
          :border="false"
          class="file-text"
        />

        <van-field
          class="file-val"
          size="large"
          v-model="from.legalPersonCitizenIdNo"
          name="legalPersonCitizenIdNo"
          placeholder="请输入"
          maxlength="18"
          :rules=rules.legalPersonCitizenIdNo
        />
      </div>

      <div class="text">


        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="企业注册地"
          readonly
          label-width="150"
          :border="false"
          class="file-text"
        />

        <van-field
          class="file-val file-holder"
          size="large"
          v-model="from.companyAddress"
          name="companyAddress"
          placeholder="请输入您的企业注册地址"
          maxlength="50"
          type="textarea"
          :rules=rules.companyAddress
        />
      </div>

      <!--      委托人  -->
      <div v-show="company_info.applierType==='2'">
      <van-field
        label-width="200"
        required
        input-align="right"
        error-message-align="right"
        size="large"
        v-model="from.agentPersonName"
        label="委托授权人姓名"
        name="agentPersonName"
        placeholder="请填写"
        maxlength="20"
        :rules=rules.agentPersonName
      />

      <div class="text">

        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="委托授权人身份证号"
          readonly
          label-width="180"
          :border="false"
          class="file-text"
        />

        <van-field
          class="file-val"
          size="large"
          v-model="from.agentPersonCitizenIdNo"
          name="agentPersonCitizenIdNo"
          placeholder="请输入"
          maxlength="18"
          :rules=rules.agentPersonCitizenIdNo
        />
      </div>


      <div class="img-box">
        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="企业授权委托书扫描件"
          readonly
          label-width="200"
          :border="false"
          class="file-text"
        />

        <p>图片格式仅限jpg、png格式，大小不超过3M</p>

        <van-field
          name="authorizationLetterAttmId"
          :rules=rules.authorizationLetterAttmId
          class="uploader img-up">
          <template #input>
            <verified-upload
              :accept="accept"
              :maxsize="3"
              class="field-img"
              v-model="from.authorizationLetterAttmId"
              fileTypeCode="1012"
              :bg=bg
            ></verified-upload>
          </template>
        </van-field>


      </div>
      </div>


      </div>




      <div class="img-from">

<!--        <p class="img-name">请上传实名认证附件</p>-->
        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="请上传实名认证附件"
          readonly
          label-width="200"
          :border="false"
          class="file-text"
        />

        <p class="directions">图片格式要求jpg、jpeg、bmp、png，不超过3M；所有上传照片需清晰且未遮挡，上传证件信息需在证件有效期内。</p>
        <div v-show="company_info.applierType==='1'">
        <van-field
          name="agentPersonCitizenIdPhotoFrontAttmId"
          :rules=rules.legalPersonCitizenIdPhotoFrontAttmId
          class="uploader-user">
          <template #input>
            <verified-upload
              v-model="from.legalPersonCitizenIdPhotoFrontAttmId"
              :bg="imgList.img1"
              :accept="accept_user"
              :maxsize="3"
              fileTypeCode="1002"
            ></verified-upload>
          </template>
        </van-field>

        <p class="img-title">证件人像面</p>

        <van-field
          name="agentPersonCitizenIdPhotoBackAttmId"
          :rules=rules.legalPersonCitizenIdPhotoBackAttmId
          class="uploader-user">
          <template #input>
            <verified-upload
              v-model="from.legalPersonCitizenIdPhotoBackAttmId"
              :bg="imgList.img2"
              :accept="accept_user"
              :maxsize="3"
              fileTypeCode="1003"
            ></verified-upload>
          </template>
        </van-field>

        <p class="img-title">证件国徽面</p>
        </div>

        <div v-show="company_info.applierType==='2'">
          <van-field
            name="agentPersonCitizenIdPhotoFrontAttmId"
            :rules=rules.agentPersonCitizenIdPhotoFrontAttmId
            class="uploader-user">
            <template #input>
              <verified-upload
                v-model="from.agentPersonCitizenIdPhotoFrontAttmId"
                :bg="imgList.img1"
                :accept="accept_user"
                :maxsize="3"
                fileTypeCode="1002"
              ></verified-upload>
            </template>
          </van-field>

          <p class="img-title">证件人像面</p>

          <van-field
            name="agentPersonCitizenIdPhotoBackAttmId"
            :rules=rules.agentPersonCitizenIdPhotoBackAttmId
            class="uploader-user">
            <template #input>
              <verified-upload
                v-model="from.agentPersonCitizenIdPhotoBackAttmId"
                :bg="imgList.img2"
                :accept="accept_user"
                :maxsize="3"
                fileTypeCode="1003"
              ></verified-upload>
            </template>
          </van-field>

          <p class="img-title">证件国徽面</p>
        </div>
      </div>



    </van-form>
    </div>
  </div>

  <div class="btn">
    <van-button class="btn-right" color="#F5DEDD" @click="cancel">上一步</van-button>
    <van-button class="btn-left" color="#EA0B06" @click="handleSubmit">提交认证</van-button>
  </div>

</div>
</template>

<script>
import verified_upload from '@/page/verified/components/verified_upload'
import { mapActions, mapGetters , mapState} from 'vuex'
import {
  applyCompanyCertification,
  getH5CurrentCompany
} from "@/api/res-company";
import {Toast} from "vant";
export default {
  name: 'uerinfo_company',
  components:{
    'verified-upload':verified_upload
  },
  data () {
    return {
      imgList:{
        img1:require('@/assets/img/verified_per1.png'),
        img2:require('@/assets/img/verified_per2.png'),
      },
      state:{
        applierType:false,
      },
      company_info:{
        applierType:'',
      },
      applier_type:'',
      bg: require('@/assets/img/company1.png'),
      accept:'.jpg,.png',
      accept_user:".jpg,.jpeg,.bmp,.png",
      from: {
        applierType: "",
        legalPersonName: "",
        legalPersonCitizenIdNo: "",
        companyAddress: "",
        agentPersonName: "",
        agentPersonCitizenIdNo: "",
        agentPersonCitizenIdPhotoFrontAttmId: "", //委托人像面
        agentPersonCitizenIdPhotoBackAttmId: "",  //委托人国徽面
        legalPersonCitizenIdPhotoFrontAttmId:"", //法定人像面
        legalPersonCitizenIdPhotoBackAttmId:"", //法定人国徽面
        contactPhone: "",
        authorizationLetterAttmId:''
      },
      rules: {
        applierType:[
          { required: true, message: "请选择申请人类型", trigger: "onBlur" },
        ],
        legalPersonName: [
          { required: true, message: "请输入法定代表人姓名", trigger: "onBlur" },
        ],
        legalPersonCitizenIdNo: [
          { required: true,  trigger: "onBlur" },
          { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '身份证号格式错误！',trigger: "onBlur"},
        ],
        companyAddress: [
          { required: true,  trigger: "onBlur" },
        ],
        agentPersonName: [
          { required: true,  trigger: "onBlur" },
        ],
        agentPersonCitizenIdNo: [
          {
            required: true,
            trigger: "onBlur",
          },
          { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '身份证号格式错误！',trigger: "onBlur"},
        ],
        agentPersonCitizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "onBlur" },
        ],
        agentPersonCitizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "onBlur" },
        ],
        legalPersonCitizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "onBlur" },
        ],
        legalPersonCitizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "onBlur" },
        ],
        authorizationLetterAttmId: [
          {
            required: true,
            message: "委托人授权书",
            trigger: "onBlur",
          },
        ],
      },
      actions:[
        {
          name:'法定代表人',
          itemValue:"1",
        },
        {
          name:'授权委托人',
          itemValue:"2",
        }
      ]
    }
  },

  computed:{
    ...mapGetters(['dict']),
    ...mapState(['infoList'])
   /*  infoList:function (){
      return this.$route.query
    } */
  },
  async mounted () {
    document.title = '企业认证'

    await this.loadCompany()
    await this.LoadDictData(['COMPANY_APPLIER_TYPE'])
    this.applier_type=this.dict['COMPANY_APPLIER_TYPE']
  },

  methods:{
    ...mapActions(['LoadDictData']),

    onSelect(value,name){
      this.from[name]=value.name
      this.company_info[name]=value.itemValue
      this.state[name]=false
      if(name==='applierType' && value.itemValue==='1'){
        this.from.agentPersonName=null
        this.from.agentPersonCitizenIdNo=null
        this.from.authorizationLetterAttmId=null
        this.from.agentPersonCitizenIdPhotoFrontAttmId=null
        this.from.agentPersonCitizenIdPhotoBackAttmId=null
      }
      if(name==='applierType' && value.itemValue==='2'){
        this.from.legalPersonCitizenIdPhotoFrontAttmId=null
        this.from.legalPersonCitizenIdPhotoBackAttmId=null
      }
    },

    onCancel(){
      this.state.applierType = false
    },





    pickerChange(name){
      this.state[name]=true
    },


    loadCompany() {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });
      getH5CurrentCompany().then((company) => {

        for(let i in company) {
          let exist = this.from.hasOwnProperty(i)
          if (exist) {
            this.from[i] = company[i]
          }
        }

          if(company.applierType){
            let map={'1':'法定代表人','2':'授权委托人'}
            this.company_info.applierType=company.applierType
            this.from.applierType=map[company.applierType]
          }else {
             this.company_info.applierType='1'
              this.from.applierType='法定代表人'
          }

        Toast.clear()
      });
    },

    handleSubmit(){
      if(this.company_info.applierType==='1'){
        console.log('---------')
        this.rules.agentPersonCitizenIdNo=[{required:false}]
        this.rules.agentPersonName=[{required:false}]
        this.rules.authorizationLetterAttmId=[{required:false}]
        this.rules.agentPersonCitizenIdPhotoFrontAttmId=[{required:false}]
        this.rules.agentPersonCitizenIdPhotoBackAttmId=[{required:false}]
      }
      if(this.company_info.applierType==='2'){
        this.rules.legalPersonCitizenIdPhotoFrontAttmId=[{required:false}]
        this.rules.legalPersonCitizenIdPhotoBackAttmId=[{required:false}]
      }

      this.$refs.ruleForm.validate().then(()=>{

        Toast.loading({
          message: '提交中',
          forbidClick: true,
        });

        for(let i in this.from){
          let exist=this.company_info.hasOwnProperty(i)
          if(!exist){
            this.company_info[i]=this.from[i]
          }
        }

        console.log(this.company_info,'this.company_info')

        for(let i in this.company_info){
          console.log(this.company_info[i],'this.company_info[i]')
          this.infoList[i]=this.company_info[i]
        }

        this.infoList.verifyStatus = '2'


        applyCompanyCertification(this.infoList).then(()=>{
          Toast.clear()
          this.$router.push('/verified')
          Toast("提交成功");
        })

      })
    },


    cancel(){
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
%text_xing{
  content: '* ';
  color: #EA0B06;
}

.uerinfo-company{
  background: #F6F6F6;
  width: 100vw;
  .from-box{
    width: 100vw;
    padding: 16px;

    ::v-deep{
      .van-cell__title{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
      }
      .van-cell__value{
        font-size: 16px;
        //font-weight: 500;
        color: #999999;
      }
      .van-cell{
        padding-right: 8px;
        &::after{
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 8px;
          bottom: 0;
          left: 8px;
          border-bottom: 1px solid #ebedf0;
        }
      }
    }

    >.title{
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    >.info{
      font-size: 12px;
      //font-weight: 500;
      color: #999999;
      margin: 5px 0 16px 0;
    }
    .from-top{
      padding:0 4px 18px 4px;
      background: #FFFFFF;
      border-radius: 4px;
    }

    .text{
      background: #FFFFFF;
      >p{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
        padding: 18px 0 12px 0;
      }
      >p:before{
        @extend %text_xing
      }
      &::v-deep{
        .van-cell{
          background: #F6F6F6;
        }
      }
    }

    .img-box{
      >:nth-child(2){
        font-size: 14px;
        //font-weight: 500;
        color: #333333;
        margin: 0 8px 12px 8px;
      }
      .img-up{
        margin: 0 8px;
      }

    }


    .img-from{
      margin-top: 12px;
      padding: 18px 0;
      background: #FFFFFF;
      border-radius: 4px;
      &::v-deep{
        .van-cell::after{
          content: none;
        }
      }
      >.directions{
        font-size: 14px;
        margin: 0 12px;
        //font-weight: 500;
        color: #333333;
        //margin: 0 8px;
      }
      %title{
        text-align: center;
        font-size: 14px;
        //font-weight: 500;
        color: #333333;
      }
      .img-title{
        @extend %title;
        margin-bottom: 16px;
      }
      >.img-title-last{
        @extend %title
      }
      >.uploader{
        ::v-deep{
          .van-uploader__wrapper{
            max-width: 263px;
            max-height: 155px;
          }
          .van-uploader__preview{
            margin: 0;
          }
          .van-uploader__preview-image{
            width: 100%;
            height: 100%;
          }
        }

      }
    }

  }

  .file-text{
    background: #FFFFFF !important;
  }
  .file-val{
    margin: 0 8px 0 8px;
    width: auto;
  }
  .file-holder{

    ::v-deep{
      .van-cell__value{
        font-size: 12px !important;
        //font-weight: 500;
        color: #999999;
      }
    }

  }



  >.btn{
    width: 100vw;
    height: 92px;
    padding: 0 16px 48px 16px;
    background: #F6F6F6;
    >.btn-right::v-deep{
      color: #EA0B06!important;
      width: 167px;
      height: 44px;
    }
    >.btn-right{
      margin-right: 9px;
    }
    >.btn-left::v-deep{
      width: 167px;
      height: 44px;
    }
  }

  .uploader{
    width: auto;
    padding: 0;
    &::v-deep{
      .van-field__control{
        display: block !important;
      }
    }
  }

  .uploader-user::v-deep{
    .van-uploader__wrapper{
      max-width: 263px;
      max-height: 155px;
    }
    .van-uploader__preview{
      margin: 0;
    }
    .van-uploader__preview-image{
      width: 100%;
      height: 100%;
    }
    img{
      width: 263px;
      height: 155px
    }
  }

  .field-img{
    &::v-deep{
      img{
        width: 60px;
        height: 60px
      }
      .van-uploader__wrapper{
        width: 60px;
        height: 60px
      }
      .van-uploader__preview-image{
        width: 60px;
        height: 60px
      }

    }
  }
}
</style>
